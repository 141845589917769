// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zPmo-hoz{flex-basis:50%;margin-left:auto}.dvdn4Is2,.zPmo-hoz{align-items:center;display:flex;justify-content:space-between}.dvdn4Is2{background-color:var(--color-body);bottom:0;box-shadow:inset 0 24px 20px -20px rgba(0,0,0,.25);flex-direction:column;flex-grow:1;left:0;opacity:0;position:fixed;right:0;top:83px;visibility:hidden;z-index:100}.YFvCKY3f{opacity:1;transition:opacity .15s;visibility:visible}@media(min-width:1229.98px){.dvdn4Is2{box-shadow:none;flex-direction:row;opacity:1;position:static;transition:none;visibility:visible}}.\\+v28ae5S{--size:1.5rem;cursor:pointer;margin-left:auto;-webkit-user-select:none;-moz-user-select:none;user-select:none}.\\+v28ae5S:hover{color:var(--color-primary)}@media(min-width:1229.98px){.\\+v28ae5S{display:none!important}}.Jlj4SLZl a,.cOhxeYjs a div:last-child{color:#fff!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"menu": "zPmo-hoz",
	"dropdown": "dvdn4Is2",
	"dropdownExpanded": "YFvCKY3f",
	"icon": "+v28ae5S",
	"auth": "Jlj4SLZl",
	"user": "cOhxeYjs"
};
module.exports = ___CSS_LOADER_EXPORT___;
