// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ByWz4Y0u{display:flex;flex-wrap:nowrap;height:100%;overflow:hidden}@media(max-width:1023.98px){.ByWz4Y0u{align-items:center;background:var(--color-gray-1);flex-direction:row-reverse;flex-shrink:0;height:6.5rem;justify-content:space-between;padding:0 var(--spacing-sm)}}.fY9KA2aA{align-items:center;display:flex;flex-wrap:nowrap;overflow:hidden}@media(hover:hover){.fY9KA2aA:hover .PFQjEnLY{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.fY9KA2aA:active .PFQjEnLY{-webkit-text-decoration:underline;text-decoration:underline}}@media(max-width:1023.98px){.fY9KA2aA{padding-right:var(--spacing-sm)}}.\\+bjmrCTG{border-radius:9999px;flex-shrink:0;font-size:.6rem;height:1.5rem;margin-right:var(--spacing-sm);overflow:hidden;width:1.5rem}@media(max-width:1023.98px){.\\+bjmrCTG{height:2.5rem;width:2.5rem}}.PFQjEnLY{color:var(--color-primary-on-bg);font-size:.81rem;font-weight:500;line-height:1.39;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}@media(max-width:1023.98px){.PFQjEnLY{font-size:1rem;font-weight:700;line-height:1.6}}.Uy2AstBq{display:flex;flex-wrap:nowrap}@media(max-width:1023.98px){.Uy2AstBq{align-items:center}}.bZsaxg6N{--icon-size:1.25rem;color:var(--color-bg-invert)!important;padding:0 1.63rem}@media(min-width:1023.98px){.bZsaxg6N{background:none!important}}@media(min-width:1023.98px)and (hover:hover){.bZsaxg6N:hover{opacity:.6}}@media(min-width:1023.98px)and (hover:none){.bZsaxg6N:active{opacity:.6}}@media(max-width:1023.98px){.bZsaxg6N{padding:var(--spacing-xs)}.bZsaxg6N:not(:last-child){margin-right:var(--spacing-sm)}}.wLcGLf69{padding-right:0}@media(max-width:1023.98px){.wLcGLf69{display:none}}@media(min-width:1023.98px){._8svwkotD{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "ByWz4Y0u",
	"link": "fY9KA2aA",
	"name": "PFQjEnLY",
	"avatar": "+bjmrCTG",
	"btns": "Uy2AstBq",
	"btn": "bZsaxg6N",
	"btnLogoutDesktop": "wLcGLf69",
	"btnLogoutMobile": "_8svwkotD"
};
module.exports = ___CSS_LOADER_EXPORT___;
