// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Y6-jN8pF .el-dialog__body{padding-bottom:var(--spacing-lg)}.VeUIICYQ{align-items:flex-start;display:flex}.e635T-KG{--icon-size:1rem;margin-right:var(--spacing-s);margin-top:var(--spacing-xs)}._927ZBtUg{font-size:1.44rem;font-weight:700;line-height:1.4}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"dialog": "Y6-jN8pF",
	"title": "VeUIICYQ",
	"titleBtn": "e635T-KG",
	"titleText": "_927ZBtUg"
};
module.exports = ___CSS_LOADER_EXPORT___;
