// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LO3\\+X90a{display:flex}@media(max-width:1229.98px){.LO3\\+X90a{padding:var(--spacing-sm)}}.nVocnzOY{align-items:center;display:flex;flex-wrap:nowrap;width:100%}.nVocnzOY>:not(:last-child){margin-right:var(--spacing-xs)}@media(max-width:1229.98px){.nVocnzOY>:not(:last-child){margin-right:var(--spacing-sm)}.nVocnzOY>*{flex-basis:100%;flex-shrink:1}}.tRA1t-g6{color:var(--color-bg-invert)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "LO3+X90a",
	"btns": "nVocnzOY",
	"btnLogin": "tRA1t-g6"
};
module.exports = ___CSS_LOADER_EXPORT___;
