// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h0wqUqvS{align-items:center;display:flex;flex-direction:column;flex-grow:1;min-height:100%;padding:26px;position:relative;width:100%}@media(min-width:947.98px){.h0wqUqvS{flex-direction:row;padding:0;width:auto}}@media(min-width:1023.98px){.h0wqUqvS{margin-right:1rem}}._7nn5faJx{align-items:center;color:var(--color-text);display:flex;font-size:18px;font-weight:500;justify-content:center;letter-spacing:.75px;line-height:16px;padding:16px 11px;text-align:center;white-space:nowrap}._7nn5faJx:hover{color:var(--color-primary);-webkit-text-decoration:underline;text-decoration:underline}@media(min-width:1023.98px){._7nn5faJx{font-size:16px;height:64px;padding:0 6px;text-align:left;width:auto}}@media(min-width:1229.98px){._7nn5faJx{padding:0 14px}}._7nn5faJx.sAYvVLvC{color:var(--color-primary);cursor:default}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"nav": "h0wqUqvS",
	"link": "_7nn5faJx",
	"nuxtLinkActive": "sAYvVLvC"
};
module.exports = ___CSS_LOADER_EXPORT___;
